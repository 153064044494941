/* eslint-disable react/display-name */
import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { Citation } from '@check/app-model' // TODO: only until full citation model fleshed out
import MaterialTable from 'material-table'
import styled from '@emotion/styled'

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }))

  interface Props {
    citations: Citation[],
  }

  const PaidCitationTable = ({ citations }: Props) => {
    
    return (
      <Container>
        <MaterialTable
          title="Recently Paid Citations"
          columns={[
            { title: 'Date', field: 'date' },
            { title: 'Citation Number', field: 'citationNumber' },
            { title: 'Plea', field: 'pleaType' },
            { title: 'Sale Amount', field: 'ticketAmount' },
          ]}
          data={citations}
          localization={{
            header: {
              actions: 'View'
            }
          }}
          options={{
            actionsColumnIndex: -1,
            headerStyle: {
              fontWeight: 'bold',
            }
          }}
        />
      </Container>
    )
  }
  
  export default PaidCitationTable

  export const Container = styled.div`
`