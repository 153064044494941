import React, { useEffect, useState } from "react"
import CitationTable from "../components/CitationTable"
import { Typography } from "@material-ui/core"
import styled from "@emotion/styled";
import { useCitationState, useCourtState } from "../store/data"
import { LineChart } from "../components/LineChart"
import PaidCitationTable from "../components/PaidCitationTable"
import { Card } from "@material-ui/core"
import currency from "currency.js";
import { Citation } from "@check/app-model";

export const Home: React.FC = () => {
    const { citationState } = useCitationState()
    const { courtState } = useCourtState()
    const [totalRev, setTotalRev] = useState(0)

    useEffect(() => {
        const rev = citationState?.citations?.reduce?.((curr: number, next: Citation) => next.plea?.pleaStatus == 'Complete' ? curr + parseInt(next.ticketAmount) : curr, 0)
        setTotalRev(rev)
    }, [])

    return (
        <Container>
                <Typography variant='h2' style={{ fontWeight: 400, color: 'black', paddingBottom: '25px' }}>{`${courtState.name} Dashboard`}</Typography>
                <TableContainer>
                    {/* <LineChart title='Today' xaxis={['','','','','','']} series={[{ name: 'Today', data: [245,930,1120,1349,1562,1845] }]}/> */}
                    <ComponentWrapper>
                        <StyledCard>
                            <Typography variant="h6" style={{ fontWeight: 'bold', paddingRight: '10px', color: "#1e88e5" }}>Total collected</Typography>
                            <Typography variant="h2">{currency(totalRev).format()}</Typography>
                        </StyledCard>
                    </ComponentWrapper>
                    <ComponentWrapper>
                        <PaidCitationTable citations={citationState.citations?.filter(c => c.plea?.pleaStatus === 'Complete')}/>
                    </ComponentWrapper>
                </TableContainer>
        </Container>
    )
}

const TableContainer = styled.div`
    width: 90%;
`

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
`
const StyledCard = styled(Card)`
    padding: 20px;
    width: 300px;
`

const ComponentWrapper = styled.div`
    // display: flex;
    // flex-direction: row;
    margin-top: 25px;
    margin-bottom: 25px;
`