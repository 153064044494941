import create from 'zustand'

import { CourtEmployee, Court, Citation } from "@check/app-model"
import { Persist } from 'react-persist'
import { devtools } from 'zustand/middleware'

    // USER
    export type UserState = {
        userState: CourtEmployee
        upsertUserState: (user: CourtEmployee) => void
    }

    export const useUserState = create<UserState>(set => ({
        userState: {} as CourtEmployee,
        upsertUserState: (user: CourtEmployee) => set({ userState: user})
    }))

    // COURT
    export type CourtState = {
        courtState: Court
        upsertCourtState: (court: Court) => void
    }

    export const useCourtState = create<CourtState>(set => ({
        courtState: {} as Court,
        upsertCourtState: (court: Court) => set({ courtState: court })
    }))

    // CITATION
    export type CitationStateObject = {
        citations: Citation[],
        totalRevenue: number
    }

    export type CitationState = {
        citationState: CitationStateObject,
        upsertCitationState: (citationState: CitationStateObject) => void
    }

    export const useCitationState = create<CitationState>(set => ({
        citationState: {} as CitationStateObject,
        upsertCitationState: (citations: CitationStateObject) => set({ citationState: citations })
    }))

    // APP
    export type AppState = {
        userState: CourtEmployee,
        courtState: Court,
        citationState: CitationStateObject,
    }

    export type State = {
        appState: AppState
        upsertState: (appState: AppState) => void
    }

    export const useAppState = () => {
        const { courtState, upsertCourtState } = useCourtState()
        const { userState, upsertUserState } = useUserState()
        const { citationState, upsertCitationState } = useCitationState()

        return create<State>(devtools(set => ({
            appState: {
                courtState,
                userState,
                citationState,
            },
            upsertState: (appState: AppState) => {
                upsertCourtState({
                    ...courtState,
                    ...appState.courtState,
                })
                upsertUserState({
                    ...userState,
                    ...appState.userState,
                })
                upsertCitationState({
                    ...citationState,
                    ...appState.citationState,
                })

                return set({ appState: appState })
            }
        }), new Date().toLocaleTimeString()))
    }


    export const StateComponent = () => {
        const { upsertUserState } = useUserState()
        const { upsertCourtState } = useCourtState()
        const { upsertCitationState } = useCitationState()
        const { appState } = useAppState()()

        return (
            <Persist
                name="local-save"
                data={appState}
                onMount={data => { 
                    upsertUserState(data.userState)
                    upsertCourtState(data.courtState)
                    upsertCitationState(data.citationState)
                }}
            />
        )
    }