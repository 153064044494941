import React, { useEffect } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import styled from "@emotion/styled"
import { SignUpPage } from "./pages/SignUp.page"
import { SignInPage } from "./pages/SignIn.page"
import { Dashboard } from "./pages/Dashboard.page"


const App: React.FC = () => {
  useEffect(() => console.log('refresh'), [])

  return (
    <Router>
      <Root>
        <>
              <Switch>
                <Route path="/signup" component={SignUpPage} />
                <Route path="/signin" component={SignInPage} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/" component={SignInPage} />
              </Switch>
        </>
      </Root>
    </Router>
  )
}

export default App

const Root = styled.div`
  height: 100vh;
  width: 100%;
  display: grid;
  grid-template-rows: 72px auto;

  * {
    /* width */
    ::-webkit-scrollbar {
      width: 8px;
      padding-right: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: none;
      /* margin-left: 8px; */
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
      transition: background 200ms;
      border-radius: 4px;
      width: 8px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`