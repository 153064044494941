/* eslint-disable react/display-name */
import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { MoreVert } from '@material-ui/icons'

import { Citation } from '@check/app-model' // TODO: only until full citation model fleshed out
import { useCitationState } from '../store/data'

import MaterialTable from 'material-table'
import styled from '@emotion/styled'
import CitationDrawer from './CitationDrawer'
import { updateCitation } from '../api/mutations/updateCitation.mutation'
import { queryCitationsByCourtId } from '../api/queries/queryCitationsByCourtId.query'
import { useMutation } from 'react-query'

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }))

  interface Props {
    citations: Citation[],
    courtId: string | undefined
  }

  
  const CitationTable = ({ citations, courtId }: Props) => {
    const [selectedCitation, setSelectedCitation] = useState({} as Citation)

    const { citationState, upsertCitationState } = useCitationState()

    // const updateCitationMutation = useMutation(updateCitation, {
    //   onSuccess: async data => {
    //     const newCitations: Citation[] = await queryCitationsByCourtId({ courtId: courtId || '' })
    //     upsertCitationState({ ...citationState, citations: newCitations})
    //   }
    // })

    const updateCitationMutation = (citation: Citation) => {
      const tempState = { ...citationState, citations: citations.map((c) => c.id === citation.id ? citation : c) }
      console.log(tempState)
      upsertCitationState(tempState)
    }
    
  
    return (
      <Container>
        <MaterialTable
          title="Citations"
          columns={[
            { title: 'Date', field: 'date' },
            { title: 'Citation Number', field: 'citationNumber' },
            { title: 'Drivers License', field: 'driversLicense' },
            { title: 'License Plate', field: 'licensePlate' },
            { title: 'Court Status', field: 'plea.pleaStatus' },
            { title: 'Plea', field: 'plea.pleaType' },
            { title: 'Violation', field: 'citationType' },
          ]}
          data={citations}
          actions={[
            {
              icon: () => <MoreVert />,
              tooltip: 'View User Info',
              onClick: (event, rowData) => setSelectedCitation(rowData as Citation)
            },
          ]}
          localization={{
            header: {
              actions: 'View'
            }
          }}
          options={{
            actionsColumnIndex: -1,
            headerStyle: {
              fontWeight: 'bold',
            }
          }}
        />
        {
          citations?.map((c, i) => <CitationDrawer key={i} citation={c} open={c.id === selectedCitation.id} onClose={() => setSelectedCitation({} as Citation)} update={(citation: Citation) => updateCitationMutation( citation )}/>)
        }
      </Container>
    )
  }
  
  export default CitationTable

  export const Container = styled.div`
`