import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { QueryClient, QueryClientProvider } from "react-query"
import { StateComponent } from "./store/data"

const queryClient = new QueryClient()

const Providers: React.FC = ({ children }) => {
  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <React.StrictMode>
          <StateComponent />
          {children}
        </React.StrictMode>
      </QueryClientProvider>
    </React.Fragment>
  )
}

ReactDOM.render(
  <Providers>
    <App />
  </Providers>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
