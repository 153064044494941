/* eslint-disable react/display-name */
import React from 'react'
import { Citation, PleaStatus, Plea } from '@check/app-model' // TODO: only until full citation model fleshed out
import styled from '@emotion/styled'
import { Drawer, Typography, Card, CardContent, MenuItem, Select, FormControl, InputLabel } from '@material-ui/core'

  interface Props {
    citation: Citation,
    open: boolean,
    onClose: () => void
    update: (citation: Citation) => void
  }

  
  const CitationDrawer = ({ citation, open, onClose, update }: Props) => {

    const InformationElement = (props: { property: React.ReactNode; info: React.ReactNode }) => (
        <InfoElem>
            <Typography variant="h6" style={{ fontWeight: 'bold', paddingRight: '10px' }}>{props.property}</Typography>
            <Typography variant="subtitle2">{props.info}</Typography>
        </InfoElem>
    )

    const InformationCard = () => (
        <StyledCard>
            <CardContent>
                <InformationElement property='Citation # ' info={citation.citationNumber}/>
                <InformationElement property='Date ' info={citation.date}/>
                <InformationElement property='Drivers License # ' info={citation.driversLicense}/>
                <InformationElement property='Plea ' info={citation?.plea?.pleaType}/>
                <InformationElement property='Violation ' info={citation.citationType}/>
                <InformationElement property='Status ' info={citation?.plea?.pleaStatus}/>
            </CardContent>
        </StyledCard>
    )

    const ImageCard = (props: { image: string }) => (
        <StyledCard>
            <CardContent>
                <Image src={props.image} />
            </CardContent>
        </StyledCard>
    )

    const ChangeStatusCard = () => (
        <StyledCard>
            <CardContent>
            <FormControl variant="outlined">
                <InputLabel 
                id="change-status">
                    Change Status
                </InputLabel>
                <Select
                variant="outlined"
                labelId="change-status"
                style={{width: '400px'}}
                label='Change Status'
                onChange={(e) =>  update({
                    ...citation,
                    plea: {
                        ...citation.plea,
                        pleaStatus: e.target.value as PleaStatus
                    } as Plea
                })}
                >
                    <MenuItem value='Open'>Open</MenuItem>
                    <MenuItem value='Pending'>Pending</MenuItem>
                    <MenuItem value='Complete'>Complete</MenuItem>
                </Select>
            </FormControl>
            </CardContent>
        </StyledCard>
    )
  
    return (
        <Drawer anchor={'right'} open={open} onClose={onClose}>
            <Container>
                <ImageCard image='/dummy_dl.png' />
                <ImageCard image='/dummy_insurance.png' />
                <InformationCard />
                <ChangeStatusCard />
            </Container>
        </Drawer>
    )
  }
  
  export default CitationDrawer

  export const Container = styled.div`
    padding: 25px;
  `

  export const InfoElem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
  `

  export const StyledCard = styled(Card)`
    margin-top: 10px;
    margin-bottom: 10px;
  `

  export const Image = styled.img`
    width: 400px;
  `