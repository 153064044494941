import React, { useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsIcon from '@material-ui/icons/Settings';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useHistory } from 'react-router-dom'



const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      color: "#B4AFCD",
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      background: "#0848A6",
      color: "#B4AFCD",
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
      background: "#0848A6",
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    listItems: {
        color: "#B4AFCD"
    }
  }),
);

const Sidebar = (props: { children: React.ReactNode; }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <List className={classes.listItems}>
            <ListItem 
              onClick={toggleDrawer} 
              button key="menu">
                <ListItemIcon className={classes.listItems}><MenuIcon /></ListItemIcon>
                <ListItemText primary="Close Menu" />
            </ListItem>
            <ListItem 
              selected={history.location.pathname === '/dashboard/home'}
              onClick={() => history.push('/dashboard/home')} 
              button key="home">
                <ListItemIcon className={classes.listItems}><HomeIcon /></ListItemIcon>
                <ListItemText primary="Home" />
            </ListItem>
            <ListItem 
              selected={history.location.pathname === '/dashboard/citations'}
              onClick={() => history.push('/dashboard/citations')}
              button key="citations">
                <ListItemIcon className={classes.listItems}><AssignmentIcon /></ListItemIcon>
                <ListItemText primary="Citations" />
            </ListItem>
            <ListItem 
              selected={history.location.pathname === '/dashboard/settings'}
              onClick={() => history.push('/dashboard/settings')} 
              button key="settings">
                <ListItemIcon className={classes.listItems}><SettingsIcon /></ListItemIcon>
                <ListItemText primary="Settings" />
            </ListItem>
            <ListItem 
              selected={history.location.pathname === '/dashboard/notifications'}
              onClick={() => history.push('/dashboard/notifications')} 
              button key="notifications">
                <ListItemIcon className={classes.listItems}><NotificationsIcon /></ListItemIcon>
                <ListItemText primary="Notifications" />
            </ListItem>
        </List>
      </Drawer>
      {props.children}
    </div>
  );
}

export default Sidebar