import React from 'react'
import styled from "@emotion/styled"
import { css } from '@emotion/react'

const baseInputStyle = css`
  border-radius: 4px;
  display: block;
  padding: 16px 16px;
  background: none;
  border: #ddd thin solid;
  font-size: 18px;
  outline: none;
  width: 100%;
  &:focus {
    box-shadow: 0px 0px 4px rgba(135, 124, 211, 0.67);
    border-color: #4D66F2;
  }
`
export const Input = styled.input(baseInputStyle)