import { getRequest } from ".."

export interface QueryCourtByNameAndEmailOpts {
  name: string,
  email: string
}
export const queryCourtByNameAndEmail = async function({ name, email }: QueryCourtByNameAndEmailOpts) {
  const url = `/courts/${name}/${email}`
  const fetchOpts: RequestInit = {
    method: "get",
  }

  const response = await getRequest(url)
  const data = await response
  return data
}