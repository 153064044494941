import React from 'react'
import styled from "@emotion/styled"
import { css } from '@emotion/react'

const styles = css`
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05em;
  border-radius: 8px;
  font-size: 18px;
  color: white;
  text-decoration: none;
  text-align: center;
  padding: 24px;
  border: none;
  background: #4D66F2;
  background: linear-gradient(86.74deg, #3626A7 0%, #3C28C9 100%);
  box-shadow: 0px 4px 4px rgba(135, 124, 211, 0.67);
  transition: background 300ms, box-shadow 300ms;
  cursor: pointer;
  outline: none;
  &:hover, &:focus {
    box-shadow: 0px 6px 6px rgba(135, 124, 211, 0.67);
  }
  &:disabled {
    background: #c4c4c4;
    box-shadow: none;
    cursor: not-allowed;
  }
`

const outlineStyle = css`
  ${styles}
  background: none;
  box-shadow: none;
  border: #4D66F2 2px solid;
  color: #4D66F2;
  &:hover, &:focus {
    box-shadow: non e;
    background: none;
  }
  &:disabled {
    box-shadow: none;
    background: none;
    cursor: not-allowed;
  }

`

export const AnchorButton = styled.a(styles)
export const Button = styled.button(styles)
export const OutlineAnchorButton = styled.a(outlineStyle)