import { Citation } from "@check/app-model"
import { getRequest } from ".."

export interface QueryCitationsByCourtId {
  courtId: string,
}
export const queryCitationsByCourtId = async function({ courtId }: QueryCitationsByCourtId): Promise<Citation[]> {
  const url = `/citations/court/${courtId}`
  const fetchOpts: RequestInit = {
    method: "get",
  }

  const response = await getRequest(url)
  const data = await response
  return data as Citation[]
}