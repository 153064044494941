import styled from "@emotion/styled";

export const ProgressBar = (props: { bgcolor: string, percentage: number }) => {
    const { bgcolor, percentage } = props;


    const Container = styled.div`
        height: 7px;
        width: 250px;
        background-color: #e0e0de;
        border-radius: 5px;
    `

    const Filler = styled.div`
        height: 100%;
        width: ${percentage}%;
        background-color: ${bgcolor};
        border-radius: inherit;
    `
  
    return (
      <Container>
        <Filler />
      </Container>
    );
  };