import dotenv from "dotenv"
dotenv.config()

interface Config {
  env: string
  api: {
    url: string
  }
  auth: {
    domain: string
    clientId: string
    audience: string
    baseNameSpace: string
  }
  isProd: boolean
  tagManager: {
    analyticsId: string
  }
}

const defaults = {
  env: "development",
  api: {
    url: process.env.REACT_APP_API_URL ?? "https://api.dev.getcheckapp.com",
  },
  blinkIdKey: process.env.REACT_APP_BLINKID_KEY ?? "sRwAAAYbZHJpdmVycy5kZXYuZ2V0Y2hlY2thcHAuY29t5gy156kxzd6erE3Ry031yCPnn7iAm3Zcp8ooVi2p+6SqRJbiiQjZwYaZ85oP1S0lldQo5jwyZL8PcIk/uF6skMudYMqmO9V8DMGM/4XshlMhLAbwNrJ6qUQoA3hdKQJXbxMr7f9xVUJVBsqob1zHG5Otz4LHc2P2OrI5w/J+ZUlUejuQ5TqSokt8I9Jj37a05olxurM4isR5rdnhlBUWKthOJ/JDFQKmp0593btOP/w+W5Xad/yKyYKdOLjFnPxSpnKeQl76Od+xCY2uIcoyU/ci3HTT3Bp6TczZDJeWyrPb3Y84lXgXq+WT7y88xLuOY9nBcz81",
  auth: {
    domain: process.env.REACT_APP_AUTH_DOMAIN ?? "dev-pllg2po5.us.auth0.com",
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID ?? "u1ZpEMkcJIaJ6hmecX8hcM7BK2vsIhcr",
    audience: process.env.REACT_APP_AUTH_AUDIENCE ?? "https://api.getcheckapp.com/",
    baseNameSpace: process.env.REACT_APP_AUTH_BASE_NAMESPACE ?? "https://api.getcheckapp.com",
  },
  tagManager: {
    analyticsId: process.env.REACT_APP_TAG_MANAGER ?? ""
  },
}

const isProd = process.env.NODE_ENV === "production"

const config = {
  env: defaults.env,
  isProd: isProd,
  auth: {
    domain: defaults.auth.domain,
    clientId: defaults.auth.clientId,
    audience: defaults.auth.audience,
    baseNameSpace: defaults.auth.baseNameSpace,
  },
  api: {
    url: defaults.api.url,
  },
  tagManager: {
    analyticsId: defaults.tagManager.analyticsId,
  },
}

export default config as Config
