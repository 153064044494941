import { BaseResponse } from "@check/app-model"
import { postRequest } from ".."
import { Court } from "@check/app-model"

export interface CreateNewCourtOpts {
  court: Court
}

export const createNewCourt = async ({ court }: CreateNewCourtOpts) => {
  const response = await postRequest<BaseResponse<any>>({
    endpoint: "/courts",
    body: {
      id: "create",
      attributes: { ...court },
    },
  })
  return response
}