import { BaseRequest, BaseResponse } from "@check/app-model"
import axios from "axios"

import config from "../config"

const apiClient = axios.create({
  baseURL: `${config.api.url}/api/v1`,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
})
export default apiClient

export const postRequest = async <ResponseType>(
  request: BaseRequest
): Promise<ResponseType | undefined> => {
  const response = await apiClient.post<BaseResponse<ResponseType>>(
    request.endpoint,
    { data: request.body },
    {
      headers: {
        ...apiClient.defaults.headers,
        ...request.headers,
      },
    }
  )

  if (response.status >= 400) {
    throw response.data.errors ?? []
  }
  return response.data.data
}

// TODO: Refactor this to be more robust - need to rethink api abstraction in general
// need to fix headers and url params
export const getRequest = async <ResponseType>(
  url: string,
  headers?: Record<string, string>
): Promise<ResponseType | undefined> => {
  const response = await apiClient.get<BaseResponse<ResponseType>>(url, {
    headers: {
      ...apiClient.defaults.headers,
      ...headers,
    },
  })

  if (response.status >= 400) {
    throw response.data.errors ?? []
  }
  return response.data.data
}

export const putRequest = async <ResponseType>(
  url: string,
  body?: object,
  headers?: Record<string, string>
): Promise<ResponseType | undefined> => {
  const response = await apiClient.put<BaseResponse<ResponseType>>(
    url,
    { data: body },
    {
      headers: {
        ...apiClient.defaults.headers,
        ...headers,
      },
    }
  )

  if (response.status >= 400) {
    throw response.data.errors ?? []
  }
  return response.data.data
}
