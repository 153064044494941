import React, { useEffect, useState } from "react"
import CitationTable from "../components/CitationTable"
import { Typography } from "@material-ui/core"
import styled from "@emotion/styled";
import { useCitationState, useCourtState } from "../store/data"
import { Citation } from "@check/app-model"

export const Citations: React.FC = () => {
    const { citationState } = useCitationState()
    const { courtState } = useCourtState()

    return (
        <Container>
                <Typography variant='h2' style={{ fontWeight: 400, color: 'black', paddingBottom: '25px' }}>Citations</Typography>
                <TableContainer>
                    <CitationTable citations={ citationState.citations } courtId={ courtState.id }/>
                </TableContainer>
        </Container>
    )
}

const TableContainer = styled.div`
    width: 90%;
`

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
`