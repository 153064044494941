import { ProgressBar } from "../components"
import styled from '@emotion/styled'
import { CourtEmployee, Court} from '@check/app-model'
import {Button, Select, TextField, Typography, MenuItem, InputLabel, FormControl, Card, CardContent, CardHeader, IconButton} from '@material-ui/core'
import { HighlightOffOutlined } from '@material-ui/icons'
import { createNewCourt } from "../api/mutations/createNewCourt.mutation"
import { Link } from "react-router-dom"
import { useState } from "react"
import { useMutation } from "react-query"

export const SignUpPage: React.FC = () => {

  const [formSection, setFormSection] = useState(1)

  // Court Details
  const [courtName, setCourtName] = useState('')
  const [countyName, setCountyName] = useState('')
  const [courtWebsite, setCourtWebsite] = useState('')
  const [courtLogoUrl, setCourtLogoUrl] = useState('')
  const [detailsFailedValidation, setDetailsFailedValidation] = useState(false)

  // Court Bank Details
  const [bankName, setBankName] = useState('')
  const [bankRouting, setBankRouting] = useState('')
  const [bankNumber, setBankNumber] = useState('')
  const [bankDetailsFailedValidation, setBankDetailsFailedValidation] = useState(false)

  // Court Fine Schedule
  const [fineSchedule, setFineSchedule] = useState('') // Need to provide opportunity to add actual fine schedule
  const [fineScheduleFailedValidation, setFineScheduleFailedValidation] = useState(false)

  // Court Employee Details
  const [employees, setEmployees] = useState<CourtEmployee[]>([{ firstName: '', lastName: '', email: '', role: ''} as CourtEmployee])
  const [employeesFailedValidation, setEmployeesFailedValidation] = useState(false)

  const createNewCourtMutation = useMutation(createNewCourt, {
    onSuccess: data => {
      console.log(data)
    },
    onError: console.error
  })

  const CourtDetails = () => (
    <>
        <ProgressBar percentage={0} bgcolor="#00b6a0"/>
        <Typography 
          style={{ fontWeight: 550, marginTop: '20px' }}
        >
            Enter your court details for Check citation management
        </Typography>
        <InnerContainer>
          <TextField 
            variant="outlined"
            label="Court Name"
            value={courtName}
            onChange={(e) => setCourtName(e.target.value)}
            error={detailsFailedValidation && !!!courtName}
          />
          <TextField 
            variant="outlined"
            label="County Name"
            value={countyName}
            onChange={(e) => setCountyName(e.target.value)}
            error={detailsFailedValidation && !!!countyName}
          />
          <TextField 
            variant="outlined"
            label="Court Website URL"
            value={courtWebsite}
            onChange={(e) => setCourtWebsite(e.target.value)}
            error={detailsFailedValidation && !!!courtWebsite}
          />
          <TextField 
            variant="outlined"
            label="Court Logo Image"
            value={courtLogoUrl}
            onChange={(e) => setCourtLogoUrl(e.target.value)}
            error={detailsFailedValidation && !!!courtLogoUrl}
          />
          <Button 
            onClick={validateCourtDetails}
            variant="contained" 
            color="primary" 
            disableElevation
          >
            Next
          </Button>
        </InnerContainer>
      </>
  )

  const validateCourtDetails = () => {
    const validated = !!courtName && !!countyName && !!courtWebsite && !!courtLogoUrl
    
    setDetailsFailedValidation(!validated)

    setFormSection( validated ? 2 : 1)
  }

  const CourtBankDetails = () => (
    <>
        <ProgressBar percentage={50} bgcolor="#00b6a0"/>
        <Typography 
          style={{ fontWeight: 550, marginTop: '20px' }}
        >
            Enter in the court bank account details to receive citation payments from motorists.
        </Typography>
        <InnerContainer>
          <TextField 
            variant="outlined"
            label="Bank Account Name"
            value={bankName}
            onChange={(e) => setBankName(e.target.value)}
            error={bankDetailsFailedValidation && !!!bankName}
          />
          <TextField 
            variant="outlined"
            label="Bank Account Routing Number"
            value={bankRouting}
            onChange={(e) => setBankRouting(e.target.value)}
            error={bankDetailsFailedValidation && !!!bankRouting}
          />
          <TextField 
            variant="outlined"
            label="Bank Account Number"
            value={bankNumber}
            onChange={(e) => setBankNumber(e.target.value)}
            error={bankDetailsFailedValidation && !!!bankNumber}
          />
          <Button 
            onClick={validateBankDetails}
            variant="contained" 
            color="primary" 
            disableElevation
          >
            Next
          </Button>
        </InnerContainer>
      </>
  )

  const validateBankDetails = () => {
    const validated = !!bankName && !!bankNumber && !!bankRouting
    
    setBankDetailsFailedValidation(!validated)

    setFormSection( validated ? 3 : 2)
  }

  const updateEmployee = (employee: CourtEmployee, index: number) => {
    const emp = employees.slice(0)
    emp.splice(index, 1, employee)

    setEmployees(emp)
  }

  const removeEmployee = (index: number) => {
    const emp = employees.slice(0)
    emp.splice(index, 1)

    setEmployees(emp)
  }

  const EmployeeInformationInput = (index: number,  employee: CourtEmployee) => (
    <Card>
      { employees.length > 1 &&
      <CardHeader
        action={
          <IconButton 
          onClick={() => removeEmployee(index)}
          aria-label="settings">
            <HighlightOffOutlined />
          </IconButton>
        }
      />}
      <CardContent>
        <EmployeeContainer>
          <TextField 
            variant="outlined"
            label="First Name"
            value={employees[index].firstName}
            onChange={(e) => updateEmployee({ ...employee, firstName: e.target.value}, index)}
            error={employeesFailedValidation && !!!employee.firstName}
          />
          <TextField 
            variant="outlined"
            label="Last Name"
            value={employees[index].lastName}
            onChange={(e) => updateEmployee({ ...employees[index], lastName: e.target.value}, index)}
            error={employeesFailedValidation && !!!employees[index].lastName}
          />
          <TextField 
            variant="outlined"
            label="Email Address"
            value={employees[index].email}
            onChange={(e) => updateEmployee({ ...employees[index], email: e.target.value}, index)}
            error={employeesFailedValidation && !!!employees[index].email}
          />
          <FormControl variant="outlined">
            <InputLabel 
            error={employeesFailedValidation && !!!employees[index].role}
            id="role-select-id">
                Employee Role
            </InputLabel>
            <Select
              variant="outlined"
              labelId="role-select-id"
              label='Employee Role'
              value={employees[index].role}
              onChange={(e) =>  updateEmployee({ ...employees[index], role: e.target.value as string}, index)}
              error={employeesFailedValidation && !!!employees[index].role}
            >
              <MenuItem value='clerk'>Court Clerk</MenuItem>
              <MenuItem value='supervisor'>Court Supervisor</MenuItem>
              <MenuItem value='coordinator'>Court Coordinator</MenuItem>
              <MenuItem value='assistant'>Court Clerk Assistant</MenuItem>
              <MenuItem value='administrator'>Deputy Court Administrator</MenuItem>
            </Select>
          </FormControl>
        </EmployeeContainer>
      </CardContent>
    </Card>
  )

  const addEmployee = () => {
    const emp = employees.slice(0)

    emp.push({ firstName: '', lastName: '', email: '', role: ''} as CourtEmployee)

    setEmployees(emp)
  }

  const CourtEmployeeDetails = () => (
    <>
        <ProgressBar percentage={88} bgcolor="#00b6a0"/>
        <Typography 
          style={{ fontWeight: 550, marginTop: '20px' }}
        >
            Enter individual court employees who manage citations.
        </Typography>
        <InnerContainer>
          {employees && employees.map((e, i) => <>{EmployeeInformationInput(i, e)}</>)}
          <Button 
            onClick={addEmployee}
            variant="contained" 
            color="primary" 
            disableElevation
          >
            Add Employee
          </Button>
          <Button 
            onClick={validateEmployeeDetails}
            variant="contained" 
            color="primary" 
            disableElevation
          >
            Finish Setup
          </Button>
        </InnerContainer>
      </>
  )

  const validateEmployeeDetails = () => {
    const validated = employees.reduce((val, empl) => val && !!empl.firstName && !!empl.lastName && !!empl.email && !!empl.role, true)
    
    setEmployeesFailedValidation(!validated)

    if (validated) {
      createNewCourtMutation.mutate({
        court: {
          name: courtName,
          countyName: countyName,
          logoURL: courtLogoUrl,
          websiteURL: courtWebsite,
          bankName: bankName,
          routingNumber: bankRouting,
          accountNumber: bankNumber,
          fineScheduleURL: fineSchedule,
          employees: employees
        } as Court
      })
    }
  }

  const renderFormSection = (section: number) => {
    switch(section) {
      case 1:
        return CourtDetails()
      case 2:
        return CourtBankDetails()
      case 3:
        return CourtEmployeeDetails()
      default:
        break;
    }
  }

  return (
    <div>
        <Container>
          {renderFormSection(formSection)}
          <div 
            style={{
              display: 'flex', 
              flexDirection: 'row',
              alignItems: 'baseline'
          }}>
            <Typography style={{marginRight: '10px'}}>
              Already have an account?
            </Typography>
            <Link to="/signin">Sign In</Link>
          </div>
        </Container>
    </div>
  )
}

export const Container = styled.div`
  margin: 0 auto;
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 32px;
  padding-top: 44px;
  max-width: 560px;
`

export const InnerContainer = styled.div`
  margin: 0;
  align-content: center;
  display: grid;
  grid-gap: 20px;
  padding: 32px;
  width: 300px;
`
const EmployeeContainer = styled.div`
  align-content: center;
  display: grid;
  grid-gap: 20px;
`