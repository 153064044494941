import styled from "@emotion/styled"
import {Button, TextField, Typography } from '@material-ui/core'
import { queryCourtByNameAndEmail } from '../api/queries/queryCourtByNameAndEmail.query'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useCourtState, useUserState, useCitationState, CitationStateObject } from "../store/data"
import { Court, CourtEmployee, Citation } from '@check/app-model'
import { queryCitationsByCourtId } from "../api/queries/queryCitationsByCourtId.query"

export const SignInPage: React.FC = () => {

    // TODO: Better authentication method, ideally Auth0
    const [email, setEmail] = useState('')
    const [courtName, setCourtName] = useState('')
    const history = useHistory()

    const { courtState, upsertCourtState } = useCourtState() 
    const { upsertUserState } = useUserState()
    const { upsertCitationState } = useCitationState()

    const signIn = async () => {
        const response = await queryCourtByNameAndEmail({ name: courtName, email }).then((r) => {
            updateCitations(Court.check(r as Court).id)
            return r
        })
        
        upsertCourtState(Court.check(response))
        upsertUserState(CourtEmployee.check((response as Court).employees.find(e => e.email === email)))

        history.push({
            pathname: "/dashboard/home",
            state: response
        })
    }

    const updateCitations = async (courtId: string | undefined) => {
        if (!!courtId) {
            const response: any = await queryCitationsByCourtId({ courtId })

            const citations: Citation[] = (response as Citation[]).map(c => Citation.check(c))
            const totalRevenue = citations.reduce((acc, cur) => acc + (cur.plea?.pleaStatus === "Complete" ? parseFloat(cur.ticketAmount) : 0), 0)


            const tempCitations: Citation[] = [
                {
                    id: '1',
                    citationNumber: '1235092',
                    ticketAmount: '250',
                    state: 'TX',
                    courtId: '9538502',
                    courtName: 'Denton Municipal',
                    citationType: 'Speeding',
                    userId: '1293021',
                    driversLicense: '19350274823',
                    licensePlate: 'M7BH9L',
                    plea: {
                        pleaType: "no-contest",
                        pleaStatus: "Open",
                        timestamp: (new Date()).toISOString(),
                        pdfKey: "1231"
                    },
                    requests: [],
                    date: '02/03/21',
                    citationImageKey: undefined,
                },
                {
                    id: '2',
                    citationNumber: '1125452',
                    ticketAmount: '100',
                    state: 'TX',
                    courtId: '9248592',
                    courtName: 'Denton Municipal',
                    citationType: 'U-Turn',
                    userId: '2635031',
                    driversLicense: '99850371823',
                    licensePlate: 'V9BL9L',
                    plea: {
                        pleaType: "guilty",
                        pleaStatus: "Pending",
                        timestamp: (new Date()).toISOString(),
                        pdfKey: "1231"
                    },
                    requests: [],
                    date: '09/03/20',
                    citationImageKey: undefined,
                },
                {
                    id: '3',
                    citationNumber: '4515122',
                    ticketAmount: '50',
                    state: 'TX',
                    courtId: '2995482',
                    courtName: 'Denton Municipal',
                    citationType: 'Stop Light',
                    userId: '6875901',
                    driversLicense: '78051370433',
                    licensePlate: 'I9IQ5W',
                    plea: {
                        pleaType: "no-contest",
                        pleaStatus: "Complete",
                        timestamp: (new Date()).toISOString(),
                        pdfKey: "1231"
                    },
                    requests: [],
                    date: '12/24/19',
                    citationImageKey: undefined,
                },
                {
                    id: '4',
                    citationNumber: '1335266',
                    ticketAmount: '300',
                    state: 'TX',
                    courtId: '5942961',
                    courtName: 'Denton Municipal',
                    citationType: 'Speeding',
                    userId: '6585711',
                    driversLicense: '62737047831',
                    licensePlate: 'SRVTME',
                    plea: {
                        pleaType: "guilty",
                        pleaStatus: "Complete",
                        timestamp: (new Date()).toISOString(),
                        pdfKey: "1231"
                    },
                    requests: [],
                    date: '11/02/20',
                    citationImageKey: undefined,
                },
                {
                    id: '2',
                    citationNumber: '9229092',
                    ticketAmount: '100',
                    state: 'TX',
                    courtId: '9538502',
                    courtName: 'Denton Municipal',
                    citationType: 'Speeding',
                    userId: '1113021',
                    driversLicense: '9001874923',
                    licensePlate: 'LMOH9L',
                    plea: {
                        pleaType: "no-contest",
                        pleaStatus: "Open",
                        timestamp: (new Date()).toISOString(),
                        pdfKey: "1231"
                    },
                    requests: [],
                    date: '12/03/20',
                    citationImageKey: undefined,
                },
                {
                    id: '6',
                    citationNumber: '1125002',
                    ticketAmount: '200',
                    state: 'TX',
                    courtId: '9248882',
                    courtName: 'Denton Municipal',
                    citationType: 'U-Turn',
                    userId: '2635031',
                    driversLicense: '93710898523',
                    licensePlate: 'BLV99L',
                    plea: {
                        pleaType: "no-contest",
                        pleaStatus: "Open",
                        timestamp: (new Date()).toISOString(),
                        pdfKey: "1231"
                    },
                    requests: [],
                    date: '09/25/20',
                    citationImageKey: undefined,
                },
            ]

            upsertCitationState({ citations: tempCitations, totalRevenue: 700 })
        }
    }

    return (
        <div>
            <Container>
                <TextField 
                variant="outlined"
                label="Court Name"
                value={courtName}
                onChange={(e) => setCourtName(e.target.value)}
                />
                <TextField 
                variant="outlined"
                label="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                />
                <Button 
                onClick={signIn}
                variant="contained" 
                color="primary" 
                disableElevation
                >
                    Sign In
                </Button>
                <div 
                style={{
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'baseline',
                    justifyContent: 'center'
                }}>
                    <Typography style={{marginRight: '10px'}}>
                        {`Don't have an account?`}
                    </Typography>
                    <Link to="/signup">Sign Up</Link>
                </div>
            </Container>
        </div>
    )
}

export const Container = styled.div`
    margin: 0 auto;
    align-content: center;
    align-items: center;
    display: grid;
    grid-gap: 20px;
    padding: 32px;
    padding-top: 44px;
    max-width: 300px;
`