import React from "react"
import Sidebar from "../components/Sidebar"
import styled from "@emotion/styled";
import { Route, Switch } from "react-router-dom";
import { Citations } from "./Citations.page";
import { Home } from "./Home.page"


export const Dashboard: React.FC = () => {

    return (
        <Sidebar>
           <Switch>
                <Route path="/dashboard/home" component={Home} />
                <Route path="/citations" component={Citations} />
                <Route path="/settings" component={Citations} />
                <Route path="/notifications" component={Citations} />
                <Route path="/" component={Citations} />
            </Switch>
        </Sidebar>
    )
}

const TableContainer = styled.div`
    width: 90%;
`

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
`